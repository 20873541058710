import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

import { FaArrowLeft, FaEnvelope, FaCalendarAlt } from "react-icons/fa";
import MainTemplate from "../../../components/MainTemplate/MainTemplate";
import CardBackground from "../../../components/MainTemplate/CardBackground";
import MatchPairOverallScore from "./MatchPairOverallScore";

import Tabs from "./Tabs"; // Adjust the path as necessary
import Modal from "../../../components/Modal/Modal";
import MessageModal from "./MessageModal";
import MeetingModal from "./MeetingModal";

const DeepAnalyzeComponent = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const currentRecord = state;
  const [clinicalRecords, setClinicalRecords] = useState();
  const [currentActiveTab, setCurrentActiveTab] = useState();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [recipient, setRecipient] = useState();
  const [donor, setDonor] = useState();
  const [crossmatchCompatibility, setCrossmatchCompatibility] = useState([]);
  const [hlaTypingScore, setHlaTypingScore] = useState();

  const [qualifiedPairs, setQualifiedPairs] = useState({
    primary_recipient_id: currentRecord?.primaryRecipient?._id,
    primary_donor_id: currentRecord?.primaryDonor?._id,
    secondary_recipient_id: currentRecord?.secondaryRecipient?._id,
    secondary_donor_id: currentRecord?.secondaryDonor?._id,
  });

  const toggleMessageModal = () => {
    setShowMessageModal(!showMessageModal);
  };

  const toggleMeetingModal = () => {
    setShowMeetingModal(!showMeetingModal);
  };

  const getClinicalRecords = () => {
    // API call to fetch clinical records
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical/pair`;
    const config = {
      method: "post",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: qualifiedPairs,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setClinicalRecords(response.data);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalRecords();
  }, []);

  useEffect(() => {
    if (currentActiveTab === "primary") {
      setRecipient({
        recipient: currentRecord?.primaryRecipient,
        clinical: clinicalRecords?.recipient_clinicals?.find(
          (c) => c?.recipient_id === qualifiedPairs?.primary_recipient_id
        ),
      });
      setDonor({
        donor: currentRecord?.secondaryDonor,
        clinical: clinicalRecords?.donor_clinicals?.find(
          (c) => c?.donor_id === qualifiedPairs?.secondary_donor_id
        ),
      });
    }
    if (currentActiveTab === "secondary") {
      setRecipient({
        recipient: currentRecord?.secondaryRecipient,
        clinical: clinicalRecords?.recipient_clinicals?.find(
          (c) => c.recipient_id === qualifiedPairs?.secondary_recipient_id
        ),
      });
      setDonor({
        donor: currentRecord?.primaryDonor,
        clinical: clinicalRecords?.donor_clinicals?.find(
          (c) => c.donor_id === qualifiedPairs?.primary_donor_id
        ),
      });
    }
  }, [currentActiveTab]);

  function checkCompatibility(crossmatchCompatibility) {
    // Check if the array does not have exactly two elements and handle single false condition
    if (crossmatchCompatibility.length !== 2) {
      if (
        crossmatchCompatibility.length === 1 &&
        crossmatchCompatibility[0] === false
      ) {
        return "Incompatible Pair";
      }
      return "Awaiting SAB test";
    }

    // Check if both elements in the array are true
    if (crossmatchCompatibility.every((elem) => elem === true)) {
      return "Compatible Pair";
    } else {
      return "Incompatible Pair";
    }
  }

  const getStatusColor = () => {
    const status =
      crossmatchCompatibility?.length &&
      checkCompatibility(crossmatchCompatibility);
    switch (status) {
      case "Compatible Pair":
        return "bg-green-100 text-green-800";
      case "Incompatible Pair":
        return "bg-red-100 text-red-800";
      case "Awaiting SAB test":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getStatusDotColor = () => {
    const status =
      crossmatchCompatibility?.length &&
      checkCompatibility(crossmatchCompatibility);
    switch (status) {
      case "Compatible Pair":
        return "bg-green-500";
      case "Incompatible Pair":
        return "bg-red-500";
      case "Awaiting SAB test":
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  return (
    <MainTemplate>
      <CardBackground>
        {showMessageModal && (
          <Modal onClose={toggleMessageModal}>
            {/* <ViewModal currentRecord={currentRecord} /> */}
            <MessageModal currentRecord={currentRecord} />
          </Modal>
        )}
        {showMeetingModal && (
          <Modal onClose={toggleMeetingModal}>
            {/* <ViewModal currentRecord={currentRecord} /> */}
            <MeetingModal currentRecord={currentRecord} />
          </Modal>
        )}
        <div className="w-full">
          <div className="p-4 mb-6 bg-gray-100 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold text-gray-800">
                Deep Analyze v1.0
              </h1>
              <div className="flex space-x-4">
                <button
                  onClick={() => navigate(-1)}
                  className="flex items-center px-4 py-2 text-white transition duration-300 bg-gray-500 rounded-md hover:bg-gray-600"
                >
                  <FaArrowLeft className="mr-2" />
                  Back
                </button>
                <button
                  onClick={() => toggleMessageModal(currentRecord)}
                  className="flex items-center px-4 py-2 text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
                >
                  <FaEnvelope className="mr-2" />
                  Message Consultant
                </button>
                <button
                  onClick={() => toggleMeetingModal(currentRecord)}
                  className="flex items-center px-4 py-2 text-white transition duration-300 bg-green-500 rounded-md hover:bg-green-600"
                >
                  <FaCalendarAlt className="mr-2" />
                  Schedule Meeting
                </button>
              </div>
            </div>
          </div>

          <MatchPairOverallScore pair={currentRecord} />

          <div className="mt-6">
            <Tabs
              currentRecord={currentRecord}
              clinicalRecords={clinicalRecords}
              qualifiedPairs={qualifiedPairs}
              setCurrentActiveTab={setCurrentActiveTab}
            />
          </div>
        </div>
      </CardBackground>
    </MainTemplate>
  );
};

export default DeepAnalyzeComponent;
