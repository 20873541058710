import React from 'react'
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import ManageConsentsChild from "./ManageConsentsChild";    

const ManageConsents = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <ManageConsentsChild />
      </CardBackground>
    </MainTemplate>
  )
}

export default ManageConsents