import React from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaUserMd,
  FaIdCard,
  FaCalendarAlt,
  FaComments,
  FaDownload,
} from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { GiDroplets } from "react-icons/gi";

const ViewModal = ({ currentRecord }) => {
  console.log("ViewModal currentRecord:", currentRecord);

  const {
    recipient_relation,
    gender,
    blood_group,
    national_id_type,
    country,
    state,
    city,
    donor_code,
    donor_name,
    member_title,
    last_name,
    dob,
    national_id_number,
    consent_upload,
    address,
    primary_mobile,
    secondary_mobile,
    whatsapp_number,
    email,
    comments,
    name,
    relationship,
    recipient_id,
    consultant_id,
    donor_active_status,
  } = currentRecord || {};

  const calculateAge = (birthDateString) => {
    if (!birthDateString) return "N/A";
    const birthDate = new Date(birthDateString);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(dob);
  const getNestedValue = (obj, key) => obj?.[key] || "N/A";

  const CardSection = ({ title, children, className = "w-full" }) => (
    <div className={`p-4 mb-4 bg-white rounded-lg shadow ${className}`}>
      <h3 className="mb-4 text-lg font-semibold text-gray-700">{title}</h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">{children}</div>
    </div>
  );

  const Field = ({ label, value, icon: Icon }) => (
    <div className="flex flex-col space-y-2">
      <span className="flex items-center gap-2 font-light text-gray-500">
        {Icon && <Icon className="text-gray-400" />}
        {label}
      </span>
      <span className="font-medium text-gray-700">{value}</span>
    </div>
  );

  const handleConsentDownload = () => {
    const baseUrl = process.env.REACT_APP_REPORTS_DOWNLOAD_URL;
    const fileUrl = `${baseUrl}uploads/consent_docs/${consent_upload}`;

    // Open in new window/tab
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="p-4 space-y-4">
      {/* Header Section */}
      <div className="p-4 bg-white rounded-lg shadow">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <FaUser className="text-2xl text-gray-600" />
            <h2 className="text-xl font-medium text-gray-800">{name}</h2>
          </div>
          <div className="flex items-center space-x-2">
            <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded-full">
              <FaIdCard className="text-white" />
              {donor_code}
            </span>
            <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
              <BsGenderAmbiguous />
              {getNestedValue(gender, "label")}
            </span>
            <span className="inline-flex items-center gap-1 px-2 py-1 text-xs font-semibold text-gray-500 bg-gray-200 rounded-full">
              <GiDroplets className="text-red-500" />
              {getNestedValue(blood_group, "label")}
            </span>
          </div>
        </div>
      </div>

      {/* Personal Information */}
      <CardSection title="Personal Information">
        <Field
          icon={FaUser}
          label="Member Title"
          value={member_title || "N/A"}
        />
        <Field icon={FaUser} label="Donor Name" value={donor_name || "N/A"} />
        <Field icon={FaUser} label="Last Name" value={last_name || "N/A"} />
        <Field icon={FaCalendarAlt} label="Age" value={age} />
        <Field
          icon={FaCalendarAlt}
          label="Date of Birth"
          value={dob ? new Date(dob).toLocaleDateString() : "N/A"}
        />
        <Field
          icon={FaUserMd}
          label="Recipient Relation"
          value={getNestedValue(recipient_relation, "label")}
        />
      </CardSection>

      {/* Identification Details */}
      <CardSection title="Identification Details">
        <div className="grid grid-cols-1 col-span-2 md:grid-cols-3 gap-x-16 gap-y-6">
          {/* ID Type */}
          <div className="flex flex-col">
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <FaIdCard className="w-4 h-4 text-gray-400" />
              ID Type
            </span>
            <span className="mt-2 text-base text-gray-700">
              {getNestedValue(national_id_type, "label")}
            </span>
          </div>

          {/* ID Number */}
          <div className="flex flex-col">
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <FaIdCard className="w-4 h-4 text-gray-400" />
              ID Number
            </span>
            <span className="mt-2 text-base text-gray-700">
              {national_id_number || "N/A"}
            </span>
          </div>

          {/* Consent Document */}
          <div className="flex flex-col">
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <FaDownload className="w-4 h-4 text-gray-400" />
              Consent Document
            </span>
            {consent_upload ? (
              <button
                className="flex items-center gap-2 px-4 py-1.5 mt-2 text-sm font-medium text-blue-600 transition-colors rounded-md bg-blue-50 hover:bg-blue-100 w-fit"
                onClick={handleConsentDownload}
                title="Download consent document"
              >
                <FaDownload className="w-3.5 h-3.5" />
                View Document
              </button>
            ) : (
              <span className="mt-2 text-sm italic text-gray-500">
                No document uploaded
              </span>
            )}
          </div>
        </div>
      </CardSection>

      {/* Contact Information */}
      <CardSection title="Contact Information">
        <Field
          icon={FaPhone}
          label="Primary Mobile"
          value={primary_mobile || "N/A"}
        />
        <Field
          icon={FaPhone}
          label="Secondary Mobile"
          value={secondary_mobile || "N/A"}
        />
        <Field
          icon={FaWhatsapp}
          label="WhatsApp Number"
          value={whatsapp_number || "N/A"}
        />
        <Field icon={FaEnvelope} label="Email" value={email || "N/A"} />
      </CardSection>

      {/* Address Information */}
      <CardSection title="Address Information">
        <Field
          icon={FaMapMarkerAlt}
          label="Country"
          value={getNestedValue(country, "label")}
        />
        <Field
          icon={FaMapMarkerAlt}
          label="State"
          value={getNestedValue(state, "label")}
        />
        <Field
          icon={FaMapMarkerAlt}
          label="City"
          value={getNestedValue(city, "label")}
        />
        <Field icon={FaMapMarkerAlt} label="Address" value={address || "N/A"} />
      </CardSection>

      {/* Additional Information */}
      <CardSection title="Additional Information">
        <div className="grid grid-cols-1 col-span-2 gap-6">
          {/* Donor Status Row */}
          <div className="flex flex-col">
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <FaUser className="w-4 h-4 text-gray-400" />
              Donor Status
            </span>
            <span className="flex items-center gap-2 mt-2">
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm ${
                  donor_active_status
                    ? "bg-green-100 text-green-700"
                    : "bg-red-100 text-red-700"
                }`}
              >
                {donor_active_status ? "Active" : "Inactive"}
              </span>
            </span>
          </div>

          {/* Comments Row - Full Width */}
          <div className="w-full">
            <span className="flex items-center gap-2 text-sm font-medium text-gray-500">
              <FaComments className="w-4 h-4 text-gray-400" />
              Comments
            </span>
            <div className="w-full p-4 mt-2 rounded-lg bg-gray-50">
              <span className="block text-base text-gray-700 whitespace-pre-wrap">
                {comments || "No comments available"}
              </span>
            </div>
          </div>
        </div>
      </CardSection>
    </div>
  );
};

export default ViewModal;
