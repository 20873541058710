import React from "react";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaUserMd,
  FaIdCard,
  FaCalendarAlt,
  FaComments,
  FaDownload,
} from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { GiDroplets } from "react-icons/gi";

const ViewModal = ({ currentRecord }) => {
  const {
    gender,
    blood_group,
    recipient_code,
    member_title,
    recipient_name,
    last_name,
    dob,
    treating_doctor,
    registration_number,
    address,
    pincode,
    primary_mobile,
    secondary_mobile,
    whatsapp_number,
    email,
    comments,
    name,
    national_id_type,
    national_id_number,
    consent_upload,
    country,
    state,
    city,
  } = currentRecord || {};

  const calculateAge = (birthDateString) => {
    if (!birthDateString) return "N/A";
    const birthDate = new Date(birthDateString);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(dob);

  const handleConsentDownload = () => {
    const baseUrl = process.env.REACT_APP_REPORTS_DOWNLOAD_URL;
    const fileUrl = `${baseUrl}uploads/consent_docs/${consent_upload}`;

    // Open in new window/tab
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between pb-4 mb-6 border-b border-gray-200">
        <div>
          <div className="flex items-center gap-2">
            <FaUser className="w-6 h-6 text-gray-600" />
            <h2 className="text-2xl font-semibold text-gray-800">
              {name || "N/A"}
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-1">
            <FaIdCard className="w-4 h-4 text-gray-500" />
            <p className="text-sm text-gray-600">{recipient_code || "N/A"}</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Badge color="blue" icon={<BsGenderAmbiguous />}>
            {gender?.label || "N/A"}
          </Badge>
          <Badge color="red" icon={<GiDroplets />}>
            {blood_group?.label || "N/A"}
          </Badge>
          <Badge color="green" icon={<FaCalendarAlt />}>
            {typeof age === "number" ? `${age} years` : age}
          </Badge>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <InfoSection title="Personal Information" icon={<FaUser />}>
          <InfoItem
            icon={<FaCalendarAlt />}
            label="Date of Birth"
            value={dob?.slice(0, 10)}
          />
          <InfoItem
            icon={<FaIdCard />}
            label="National ID Type"
            value={national_id_type?.label}
          />
          <InfoItem
            icon={<FaIdCard />}
            label="National ID Number"
            value={national_id_number}
          />
          <InfoItem
            icon={<FaUserMd />}
            label="Treating Doctor"
            value={treating_doctor}
          />
          <InfoItem
            icon={<FaIdCard />}
            label="Registration Number"
            value={registration_number}
          />
          <InfoItem
            icon={<FaIdCard />}
            label="Consent Upload"
            value={
              consent_upload ? (
                <div className="flex items-center gap-2">
                  <span>View Consent Document</span>
                  <button
                    onClick={handleConsentDownload}
                    className="p-1 text-blue-600 transition-colors rounded-full hover:text-blue-800 hover:bg-blue-100"
                    title="View consent file"
                  >
                    <FaDownload className="w-4 h-4" />
                  </button>
                </div>
              ) : (
                "N/A"
              )
            }
          />
        </InfoSection>

        <InfoSection title="Location Information" icon={<FaMapMarkerAlt />}>
          <InfoItem
            icon={<FaMapMarkerAlt />}
            label="Country"
            value={country?.label}
          />
          <InfoItem
            icon={<FaMapMarkerAlt />}
            label="State"
            value={state?.label}
          />
          <InfoItem
            icon={<FaMapMarkerAlt />}
            label="City"
            value={city?.label}
          />
          <InfoItem icon={<FaMapMarkerAlt />} label="Address" value={address} />
          <InfoItem icon={<FaMapMarkerAlt />} label="Pincode" value={pincode} />
        </InfoSection>

        <InfoSection title="Contact Information" icon={<FaPhone />}>
          <InfoItem
            icon={<FaPhone />}
            label="Primary Mobile"
            value={primary_mobile}
          />
          <InfoItem
            icon={<FaPhone />}
            label="Secondary Mobile"
            value={secondary_mobile}
          />
          <InfoItem
            icon={<FaWhatsapp />}
            label="WhatsApp Number"
            value={whatsapp_number}
          />
          <InfoItem icon={<FaEnvelope />} label="Email" value={email} />
        </InfoSection>

        <InfoSection title="Additional Information" icon={<FaComments />}>
          <InfoItem icon={<FaComments />} label="Comments" value={comments} />
        </InfoSection>
      </div>
    </div>
  );
};

const Badge = ({ children, color, icon }) => {
  const colorClasses = {
    blue: "bg-blue-100 text-blue-800",
    red: "bg-red-100 text-red-800",
    green: "bg-green-100 text-green-800",
  };

  return (
    <span
      className={`inline-flex items-center gap-1 px-2 py-1 text-xs font-semibold rounded-full ${colorClasses[color]}`}
    >
      {icon}
      {children}
    </span>
  );
};

const InfoSection = ({ title, children, icon }) => (
  <div className="p-4 rounded-md bg-gray-50">
    <h3 className="flex items-center gap-2 mb-3 text-lg font-medium text-gray-800">
      {icon}
      {title}
    </h3>
    <div className="space-y-2">{children}</div>
  </div>
);

const InfoItem = ({ label, value, icon }) => (
  <div className="flex flex-col">
    <span className="flex items-center gap-2 text-sm font-medium text-gray-600">
      {icon}
      {label}
    </span>
    <span className="ml-6 text-base text-gray-800">
      {React.isValidElement(value) ? value : value || "N/A"}
    </span>
  </div>
);

export default ViewModal;
