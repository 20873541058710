import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { countriesData } from "../../../components/Data/countries";
import { DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const AddModal = ({ getDataFromServer, showModal, hideModal }) => {
  let navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [{ errorLogApi }] = useAtom(gbState);
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState({
    country: "",
    title: "",
    language: { value: "en", label: "English" },
    status: { value: "draft", label: "Draft" },
    effectiveDate: dayjs(),
    expiryDate: null,
    comments: "",
    previousVersion: null,
  });

  const statusOptions = [
    { value: "draft", label: "Draft" },
    { value: "active", label: "Active" },
    { value: "archived", label: "Archived" },
  ];

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
    { value: "ne", label: "Nepalese" },
    { value: "bn", label: "Bangladeshi" },
    { value: "hi", label: "Hindi" },
    { value: "ar", label: "Arabic" },
    { value: "zh", label: "Chinese" },
    { value: "ru", label: "Russian" },
    { value: "pt", label: "Portuguese" },
    // Add more languages as needed
  ];

  useEffect(() => {
    // Convert country data to match the required schema
    setCountries(countriesData.map((c) => ({
      value: c.id,  // Using numeric ID as value
      label: c.name // Using country name as label
    })));
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate country selection
    if (!formData.country?.value || !formData.country?.label) {
      toast.error("Please select a country");
      return;
    }

    // Validate file upload
    if (!file) {
      toast.error("Please upload a consent file");
      return;
    }

    // Prepare form data
    const formDataToSend = new FormData();
    formDataToSend.append("consentFile", file);
    formDataToSend.append("country[value]", parseInt(formData.country.value));
    formDataToSend.append("country[label]", formData.country.label);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("language", formData.language.value);
    formDataToSend.append("status", formData.status.value);
    formDataToSend.append("effectiveDate", formData.effectiveDate?.toISOString());
    
    // Append optional fields
    if (formData.expiryDate) formDataToSend.append("expiryDate", formData.expiryDate.toISOString());
    if (formData.comments) formDataToSend.append("comments", formData.comments);

    try {
      // Send POST request
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/consent/add`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle successful response
      if (response.data) {
        toast.success("Consent Created Successfully.");
        getDataFromServer();
        hideModal();
      }
    } catch (error) {
      // Handle error
      console.error("Error details:", error?.response?.data);
      toast.error(error?.response?.data?.message || "Error creating consent");
      errorLogApi(error, "AddModal", "/consent");
    }
  };

  // Handle select field changes
  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption }));
  };

  // Configure upload properties
  const uploadProps = {
    beforeUpload: (file) => {
      // Validate file type
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
      if (!validTypes.includes(file.type)) {
        toast.error('You can only upload PDF, DOC, DOCX or TXT files!');
        return Upload.LIST_IGNORE;
      }

      // Validate file size
      if (file.size / 1024 / 1024 >= 5) {
        toast.error('File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }

      // Set file and prevent automatic upload
      setFile(file);
      return false;
    },
    maxCount: 1,
    onRemove: () => setFile(null),
    fileList: file ? [file] : [],
  };

  return (
    <div className="p-6 w-full bg-white rounded-lg">
      <h2 className="mb-6 text-2xl font-semibold">Add New Consent</h2>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <label className="block mb-1 text-sm font-medium">
              Country <span className="text-red-500">*</span>
            </label>
            <Select
              options={countries}
              value={formData.country}
              onChange={handleSelectChange("country")}
              placeholder="Select Country"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="p-2 w-full rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Language <span className="text-red-500">*</span>
            </label>
            <Select
              options={languageOptions}
              value={formData.language}
              onChange={handleSelectChange("language")}
              placeholder="Select Language"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Status <span className="text-red-500">*</span>
            </label>
            <Select
              options={statusOptions}
              value={formData.status}
              onChange={handleSelectChange("status")}
              placeholder="Select Status"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Effective Date <span className="text-red-500">*</span>
            </label>
            <DatePicker
              value={formData.effectiveDate}
              onChange={(date) => setFormData({ ...formData, effectiveDate: date })}
              className="p-2 w-full"
              format="YYYY-MM-DD"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Expiry Date
            </label>
            <DatePicker
              value={formData.expiryDate}
              onChange={(date) => setFormData({ ...formData, expiryDate: date })}
              className="p-2 w-full"
              format="YYYY-MM-DD"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Consent File <span className="text-red-500">*</span>
            </label>
            <Upload {...uploadProps}>
              <button type="button" className="flex gap-2 items-center p-2 rounded-md border border-gray-300 hover:border-blue-500">
                <UploadOutlined /> Upload File
              </button>
            </Upload>
            {file && (
              <p className="mt-1 text-xs text-gray-500">
                Selected file: {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
              </p>
            )}
            <p className="mt-1 text-xs text-gray-500">Supported formats: PDF, DOC, DOCX, TXT (max 5MB)</p>
          </div>
        </div>
        <div>
          <label className="block mb-1 text-sm font-medium">
            Comments
          </label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="p-2 w-full rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={() => hideModal()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add Consent
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddModal;