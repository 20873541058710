import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";
import {
  memberTitleOptions,
  genderOptions,
} from "../../../components/Data/dropdownoptions";

const UpdateModal = ({
  getDataFromServer,
  setShowUpdateModal,
  currentRecord,
}) => {
  let navigate = useNavigate();
  const [bloodgroupOptions, setBloodgroupOptions] = useState();
  const [{ errorLogApi, placeHolderText = (text) => text }] = useAtom(gbState);
  const [nidTypes, setNidTypes] = useState();
  // Add file state
  const [consentFile, setConsentFile] = useState(null);
  const [formData, setFormData] = useState({
    country: null,
    national_id_type: null,
    ...currentRecord,
  });
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    const getNiDDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/nationalidtype/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const oldData = response.data; // Access consultants array from the response data
          console.log(oldData);
          setNidTypes(oldData); // Assuming setData is a useState hook function
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };

    getNiDDataFromServer();
  }, []);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/bloodgroup/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const oldData = response.data; // Access consultants array from the response data
          //   console.log(oldData);
          const updatedData = oldData.map((data) => {
            return {
              value: data?.bloodgroup,
              label: data?.bloodgroup,
            };
          });
          setBloodgroupOptions(updatedData); // Assuming setData is a useState hook function
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };
    getDataFromServer();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      dob: dateString,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      member_title: selectedOption.value,
    });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.country) {
      toast.error("Please select a country");
      return;
    }

    if (!formData.national_id_type) {
      toast.error("Please select an ID type");
      return;
    }

    if (!formData.national_id_number) {
      toast.error("Please enter a national ID number");
      return;
    }

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/recipients/update/${currentRecord?._id}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: consentFile
        ? { ...formData, consent_upload: consentFile }
        : { ...formData },
    };

    axios(config)
      .then(function (response) {
        toast.success("Recipient Updated Successfully.");
        getDataFromServer();
        setShowUpdateModal(false);
      })
      .catch(function (error) {
        // More user-friendly error messages
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred while updating the recipient. Please try again.";
        toast.error(errorMessage);
        errorLogApi(error, "UpdateModal", "/recipients/update");
      });
  };

  return (
    <div className="w-full p-3 bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-semibold">Update Recipient</h2>
        <div className="flex space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-white bg-gray-500 rounded-md shadow-sm btn btn-secondary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={() => setShowUpdateModal(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            form="updateRecipientForm"
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Recipient
          </button>
        </div>
      </div>
      <form
        id="updateRecipientForm"
        className="space-y-4"
        onSubmit={handleAddSubmit}
      >
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="member_title"
            >
              Title
            </label>
            <Select
              name="member_title"
              options={memberTitleOptions}
              styles={style}
              placeholder={placeHolderText("Select Title")}
              onChange={(selected) =>
                dropDownChange(selected, { name: "member_title" })
              }
              value={memberTitleOptions.find(
                (option) => option.value === formData.member_title
              )}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="recipient_name"
            >
              First Name
            </label>
            <input
              type="text"
              name="recipient_name"
              id="recipient_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.recipient_name}
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.last_name}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label className="block mb-1 text-sm font-medium" htmlFor="dob">
              Date of Birth
            </label>
            <DatePicker
              name="dob"
              onChange={handleDateChange}
              className="w-full h-9"
              format="YYYY-MM-DD"
              value={formData.dob && dayjs(formData.dob, "YYYY-MM-DD")}
              disabledDate={(current) =>
                current && current > dayjs().endOf("day")
              }
            />
          </div>
          <div className="w-full md:w-1/3">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Gender
            </label>
            <Select
              name="gender"
              options={genderOptions}
              styles={style}
              placeholder={placeHolderText("Select Gender")}
              onChange={(selected) =>
                setFormData({ ...formData, gender: selected })
              }
              value={formData.gender}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="blood_group"
            >
              Blood Group
            </label>
            <Select
              name="blood_group"
              options={bloodgroupOptions}
              styles={style}
              placeholder={placeHolderText("Select Blood Group")}
              onChange={(selected) =>
                setFormData({ ...formData, blood_group: selected })
              }
              value={formData.blood_group}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
        </div>

        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-full">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="primary_mobile"
            >
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.email}
              required
            />
          </div>
        </div>
        <GeographicalComponent
          setFormData={setFormData}
          formData={formData}
          handleChange={handleChange}
        />
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="primary_mobile"
            >
              Primary Mobile
            </label>
            <input
              type="text"
              name="primary_mobile"
              id="primary_mobile"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.primary_mobile}
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="secondary_mobile"
            >
              Secondary Mobile
            </label>
            <input
              type="text"
              name="secondary_mobile"
              id="secondary_mobile"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.secondary_mobile}
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="whatsapp_number"
            >
              WhatsApp Number
            </label>
            <input
              type="text"
              name="whatsapp_number"
              id="whatsapp_number"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              value={formData.whatsapp_number}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="national_id_type"
            >
              ID Type <span className="text-red-500">*</span>
            </label>

            <Select
              name="national_id_type"
              options={
                nidTypes?.filter(
                  (type) => type.country?.label === formData?.country?.label
                ) || []
              }
              styles={style}
              placeholder={
                !formData?.country
                  ? "Select country first"
                  : placeHolderText("Select ID Type")
              }
              onChange={(selected) =>
                setFormData({ ...formData, national_id_type: selected })
              }
              value={formData.national_id_type || null}
              className="w-full"
              menuPosition="fixed"
              isSearchable={true}
              isDisabled={!formData?.country}
            />
            {formData?.country &&
              nidTypes?.filter(
                (type) => type.country?.label === formData?.country?.label
              )?.length === 0 && (
                <div className="mt-2 text-sm text-amber-600">
                  No ID types available for selected country
                </div>
              )}
            {!formData?.country ? (
              <div className="mb-2 text-sm text-amber-600">
                Please select a country first to view available ID types
              </div>
            ) : null}
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="national_id_number"
            >
              National ID Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="national_id_number"
              id="national_id_number"
              onChange={handleChange}
              value={formData.national_id_number}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              disabled={!formData.national_id_type}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-full">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="consent_upload"
            >
              Consent Upload <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name="consent_upload"
              id="consent_upload"
              onChange={(event) => setConsentFile(event.target.files[0])}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            />
            {consentFile && (
              <p className="mt-1 text-sm text-gray-500">
                Selected file: {consentFile.name}
              </p>
            )}
            {formData.consent_upload && !consentFile && (
              <div className="mt-2">
                <a
                  href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}uploads/consent_docs/${formData.consent_upload}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 underline hover:text-blue-800"
                >
                  Download Current Consent File
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="w-full">
          <label className="block mb-1 text-sm font-medium" htmlFor="comments">
            Comments
          </label>
          <textarea
            name="comments"
            id="comments"
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            value={formData.comments}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateModal;
