import React from 'react';
import { DownloadOutlined, ClockCircleOutlined, UserOutlined, GlobalOutlined, FileTextOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Badge } from 'antd';
import toast from 'react-hot-toast';

const ViewModal = ({ currentRecord, hideModal }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'success';
      case 'draft':
        return 'warning';
      case 'archived':
        return 'error';
      default:
        return 'default';
    }
  };

  const formatDate = (date) => {
    return date ? dayjs(date).format('MMM DD, YYYY') : 'N/A';
  };

  const handleDownload = async () => {
    try {
      const fileUrl = `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}${currentRecord.fileUrl}`;
      const response = await fetch(fileUrl);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const link = document.createElement('a');
      link.href = url;
      link.download = currentRecord.fileUrl.split('/').pop() || 'download';
  
      // Trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Error downloading file. Please try again.');
    }
  };
  

  return (
    <div className="p-6 mx-auto max-w-3xl bg-white rounded-lg">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-800">{currentRecord.title}</h2>
          <p className="mt-1 text-gray-500">Version: {currentRecord.version}</p>
        </div>
        <Badge 
          status={getStatusColor(currentRecord.status)} 
          text={currentRecord.status.charAt(0).toUpperCase() + currentRecord.status.slice(1)}
          className="text-sm"
        />
      </div>

      <div className="grid grid-cols-1 gap-6 mb-6 md:grid-cols-2">
        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center mb-2">
            <GlobalOutlined className="mr-2 text-blue-500" />
            <h3 className="font-semibold text-gray-700">Location Details</h3>
          </div>
          <p className="text-gray-600">Country: {currentRecord.country.label}</p>
          <p className="text-gray-600">Language: {currentRecord.language.toUpperCase()}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center mb-2">
            <ClockCircleOutlined className="mr-2 text-blue-500" />
            <h3 className="font-semibold text-gray-700">Timeline</h3>
          </div>
          <p className="text-gray-600">Effective Date: {formatDate(currentRecord.effectiveDate)}</p>
          <p className="text-gray-600">Expiry Date: {formatDate(currentRecord.expiryDate)}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center mb-2">
            <UserOutlined className="mr-2 text-blue-500" />
            <h3 className="font-semibold text-gray-700">Created By</h3>
          </div>
          <p className="text-gray-600">
            {currentRecord.createdBy.member_title} {currentRecord.createdBy.first_name} {currentRecord.createdBy.last_name}
          </p>
          <p className="text-gray-600">{currentRecord.createdBy.email}</p>
          <p className="text-sm text-gray-600">Created: {dayjs(currentRecord.createdAt).format('MMM DD, YYYY HH:mm')}</p>
        </div>

        <div className="p-4 bg-gray-50 rounded-lg">
          <div className="flex items-center mb-2">
            <FileTextOutlined className="mr-2 text-blue-500" />
            <h3 className="font-semibold text-gray-700">Document Details</h3>
          </div>
          <p className="text-gray-600">File: {currentRecord.fileUrl.split('/').pop()}</p>
          <p className="text-gray-600">Type: {currentRecord.mimeType.split('/').pop().toUpperCase()}</p>
          <button
            onClick={handleDownload}
            className="flex gap-2 items-center mt-2 text-blue-600 hover:text-blue-800"
          >
            <DownloadOutlined /> Download File
          </button>
        </div>
      </div>

      {currentRecord.comments && (
        <div className="p-4 mb-6 bg-gray-50 rounded-lg">
          <h3 className="mb-2 font-semibold text-gray-700">Comments</h3>
          <p className="text-gray-600">{currentRecord.comments}</p>
        </div>
      )}

      <div className="flex justify-end">
        <button
          onClick={hideModal}
          className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ViewModal;