import React from "react";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";
import ManageNationalIDTypesChild from "./ManageNationalIDTypesChild";

const ManageNationalIDTypes = () => {
  return (
    <MainTemplate>
      <CardBackground>
        <ManageNationalIDTypesChild />
      </CardBackground>
    </MainTemplate>
  );
};

export default ManageNationalIDTypes;
