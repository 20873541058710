import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { countriesData } from "../../../components/Data/countries";
import { DatePicker, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import toast from "react-hot-toast";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

const UpdateModal = ({ currentRecord, getDataFromServer, hideModal }) => {
  let navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [{ errorLogApi }] = useAtom(gbState);
  const [file, setFile] = useState(null);

  const [formData, setFormData] = useState({
    country: "",
    title: "",
    language: { value: "en", label: "English" },
    status: { value: "draft", label: "Draft" },
    effectiveDate: null,
    expiryDate: null,
    comments: "",
    previousVersion: null,
  });

  useEffect(() => {
    // Convert country data to match the required schema
    setCountries(countriesData.map((c) => ({
      value: c.id,
      label: c.name
    })));

    // Populate form with current record data
    if (currentRecord) {
      setFormData({
        country: {
          value: currentRecord.country.value,
          label: currentRecord.country.label
        },
        title: currentRecord.title,
        language: {
          value: currentRecord.language,
          label: currentRecord.language.toUpperCase()
        },
        status: {
          value: currentRecord.status,
          label: currentRecord.status.charAt(0).toUpperCase() + currentRecord.status.slice(1)
        },
        effectiveDate: currentRecord.effectiveDate ? dayjs(currentRecord.effectiveDate) : null,
        expiryDate: currentRecord.expiryDate ? dayjs(currentRecord.expiryDate) : null,
        comments: currentRecord.comments || "",
        previousVersion: currentRecord.previousVersion,
      });
    }
  }, [currentRecord]);

  const statusOptions = [
    { value: "draft", label: "Draft" },
    { value: "active", label: "Active" },
    { value: "archived", label: "Archived" },
  ];

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
  ];

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();

    if (!formData.country || !formData.country.value || !formData.country.label) {
      toast.error("Please select a country");
      return;
    }

    let userToken = localStorage.getItem("token");
    const formDataToSend = new FormData();

    // Append file if new file is selected
    if (file) {
      formDataToSend.append("consentFile", file);
    }

    // Append other form fields
    formDataToSend.append("country[value]", parseInt(formData.country.value));
    formDataToSend.append("country[label]", formData.country.label);

    formDataToSend.append("title", formData.title);
    formDataToSend.append("language", formData.language.value);
    formDataToSend.append("status", formData.status.value);
    formDataToSend.append("effectiveDate", formData.effectiveDate?.toISOString());
    if (formData.expiryDate) {
      formDataToSend.append("expiryDate", formData.expiryDate.toISOString());
    }
    if (formData.comments) {
      formDataToSend.append("comments", formData.comments);
    }
    if (formData.previousVersion) {
      formDataToSend.append("previousVersion", formData.previousVersion);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/consent/update/${currentRecord._id}`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      if (response.data) {
        toast.success("Consent Updated Successfully.");
        getDataFromServer();
        hideModal();
      }
    } catch (error) {
      console.error("Error details:", error?.response?.data);
      toast.error(error?.response?.data?.message || "Error updating consent");
      errorLogApi(error, "UpdateModal", "/consent/update");
    }
  };

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption }));
  };

  const uploadProps = {
    beforeUpload: (file) => {
      const isValidType = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'
      ].includes(file.type);
      
      if (!isValidType) {
        toast.error('You can only upload PDF, DOC, DOCX or TXT files!');
        return Upload.LIST_IGNORE;
      }

      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        toast.error('File must be smaller than 5MB!');
        return Upload.LIST_IGNORE;
      }

      setFile(file);
      return false;
    },
    maxCount: 1,
    onRemove: () => {
      setFile(null);
    },
  };

  return (
    <div className="p-6 w-full bg-white rounded-lg">
      <h2 className="mb-6 text-2xl font-semibold">Update Consent</h2>
      <form className="space-y-6" onSubmit={handleUpdateSubmit}>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <div>
            <label className="block mb-1 text-sm font-medium">
              Country <span className="text-red-500">*</span>
            </label>
            <Select
              options={countries}
              value={formData.country}
              onChange={handleSelectChange("country")}
              placeholder="Select Country"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Title <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="p-2 w-full rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Language <span className="text-red-500">*</span>
            </label>
            <Select
              options={languageOptions}
              value={formData.language}
              onChange={handleSelectChange("language")}
              placeholder="Select Language"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Status <span className="text-red-500">*</span>
            </label>
            <Select
              options={statusOptions}
              value={formData.status}
              onChange={handleSelectChange("status")}
              placeholder="Select Status"
              className="text-base"
              menuPosition="fixed"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Effective Date <span className="text-red-500">*</span>
            </label>
            <DatePicker
              value={formData.effectiveDate}
              onChange={(date) => setFormData({ ...formData, effectiveDate: date })}
              className="p-2 w-full"
              format="YYYY-MM-DD"
              required
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Expiry Date
            </label>
            <DatePicker
              value={formData.expiryDate}
              onChange={(date) => setFormData({ ...formData, expiryDate: date })}
              className="p-2 w-full"
              format="YYYY-MM-DD"
            />
          </div>

          <div>
            <label className="block mb-1 text-sm font-medium">
              Update Consent File
            </label>
            <Upload {...uploadProps}>
              <button type="button" className="flex gap-2 items-center p-2 rounded-md border border-gray-300 hover:border-blue-500">
                <UploadOutlined /> Upload New File
              </button>
            </Upload>
            <p className="mt-1 text-xs text-gray-500">Current file: {currentRecord?.fileUrl ? currentRecord.fileUrl.split('/').pop() : 'No file'}</p>
            <p className="mt-1 text-xs text-gray-500">Supported formats: PDF, DOC, DOCX, TXT (max 5MB)</p>
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium">
            Comments
          </label>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            className="p-2 w-full rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows={3}
          />
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500"
            onClick={() => hideModal()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Update Consent
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateModal;