import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { DownloadOutlined, } from '@ant-design/icons';

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";
import {
  memberTitleOptions,
  genderOptions,
} from "../../../components/Data/dropdownoptions";

const AddModal = ({ getDataFromServer, setShowAddModal }) => {
  let navigate = useNavigate();
  const [bloodgroupOptions, setBloodgroupOptions] = useState([]);
  const [nidTypes, setNidTypes] = useState();
  // Error Logging Service & Sanitize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [consentTemplate, setConsentTemplate] = useState("");
  const [formData, setFormData] = useState({
    member_title: "",
    recipient_name: "",
    last_name: "",
    dob: "",
    gender: "",
    treating_doctor: "",
    registration_number: "",
    blood_group: "",
    national_id_type: "",
    national_id_number: "",
    consent_upload: "",
    country: "",
    state: "",
    city: "",
    address: "",
    primary_mobile: "",
    secondary_mobile: "",
    whatsapp_number: "",
    email: "",
    comments: "",
  });

  // Add file state
  const [consentFile, setConsentFile] = useState(null);

  // Add new state for step tracking
  const [currentStep, setCurrentStep] = useState(1);

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  // When Country is selected fetch teh consent form
  useEffect(() => {
    const fetchConsentFromServer = () => {
      const country = formData.country;
      if (!country?.value) {
        setConsentTemplate("");
        return;
      }
      
      let config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/consent/country/${country.value}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
  
      axios(config)
        .then(function (response) {
          const oldData = response.data.consent;
          setConsentTemplate(oldData);
        })
        .catch(function (error) {
          console.error("Error fetching consent:", error);
          toast.error(error?.response?.data?.message || "Error fetching consent");
          errorLogApi(error, "AddModal", "/consent/country");
        });
    };

    fetchConsentFromServer();
  }, [formData.country]);

  useEffect(() => {
    const getNiDDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/nationalidtype/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const oldData = response.data; // Access consultants array from the response data
          console.log(oldData);
          setNidTypes(oldData); // Assuming setData is a useState hook function
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };

    getNiDDataFromServer();
  }, []);

  useEffect(() => {
    const fetchBloodGroups = async () => {
      try {
        const userToken = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/bloodgroup/`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        const updatedData = response.data.map((data) => ({
          value: data.bloodgroup,
          label: data.bloodgroup,
        }));

        setBloodgroupOptions(updatedData);
      } catch (err) {
        console.log("error fetching bloodgroups:", err);
        errorLogApi(err, "AddModal", "/bloodgroup");
      }
    };

    fetchBloodGroups();
  }, []); // Empty dependency array to run once on mount

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      dob: dateString,
    });
  };

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prev) => ({ ...prev, [field]: selectedOption }));
  };

  const dropDownChange = (selected, dropdown) => {
    if (!selected) {
      setFormData((prev) => ({
        ...prev,
        [dropdown.name]: null,
      }));
      return;
    }

    // Handle member_title differently
    if (dropdown.name === "member_title") {
      setFormData((prev) => ({
        ...prev,
        [dropdown.name]: selected.value,
      }));
    }

    if (dropdown.name === "gender") {
      setFormData((prev) => ({
        ...prev,
        [dropdown.name]: {
          value: selected.value,
          label: selected.label,
        },
      }));
    }

    if (dropdown.name === "blood_group") {
      setFormData((prev) => ({
        ...prev,
        [dropdown.name]: selected,
      }));
    }
  };

  // Modify the file input handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setConsentFile(file);
    setFormData({
      ...formData,
      consent_upload: file ? file.name : "", // Store just the filename in formData
    });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    // Add validation for mandatory fields
    const mandatoryFields = [
      { key: "member_title", label: "Title" },
      { key: "recipient_name", label: "First Name" },
      { key: "last_name", label: "Last Name" },
      { key: "dob", label: "Date of Birth" },
      { key: "gender", label: "Gender" },
      { key: "blood_group", label: "Blood Group" },
      { key: "primary_mobile", label: "Mobile Number" },
    ];

    const missingFields = mandatoryFields.filter(({ key }) => 
      (key === "gender" || key === "blood_group") ? !formData[key]?.value : !formData[key]
    );

    if (missingFields.length > 0) {
      toast.error(
        <div>
          <p style={{ paddingLeft: '5px', marginTop: '5px' }}>Please fill in all mandatory fields:
            {missingFields.map(({ label }, index) => (
              <span key={index}>
                {label}{index < missingFields.length - 1 ? ', ' : ''}
              </span>
            ))}
          </p>
        </div>,
        { duration: 5000 }
      );
      return;
    }

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/recipients/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
        "Content-Type": "multipart/form-data",
      },
      data: consentFile
        ? { ...formData, consent_upload: consentFile }
        : { ...formData },
    };

    axios(config)
      .then(function (response) {
        toast.success("Recipient Created Successfully.");
        getDataFromServer();
        setShowAddModal(false);
      })
      .catch(function (error) {
        // Updated error handling
        const errorMessage =
          error.response?.data || "An error occurred while creating recipient";
        toast.error(
          typeof errorMessage === "object"
            ? JSON.stringify(errorMessage)
            : errorMessage
        );
        errorLogApi(error, "AddModal", "/recipients/add");
      });
  };

  // Modify the buttons section to handle steps
  const renderButtons = () => {
    return (
      <div className="flex space-x-4">
        <button
          type="button"
          className="px-4 py-2 text-white bg-gray-500 rounded-md shadow-sm btn btn-secondary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          onClick={() =>
            currentStep === 1 ? setShowAddModal(false) : setCurrentStep(1)
          }
        >
          {currentStep === 1 ? "Cancel" : "Back"}
        </button>
        <button
          type="button"
          className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={(e) =>
            currentStep === 1 ? handleNextStep(e) : handleAddSubmit(e)
          }
        >
          {currentStep === 1 ? "Next" : "Add Recipient"}
        </button>
      </div>
    );
  };

  // Add validation and next step handler
  const handleNextStep = (e) => {
    e.preventDefault();

    console.log("Current form data:", formData); // Add this line to debug

    const mandatoryFields = [
      { key: "member_title", label: "Title" },
      { key: "recipient_name", label: "First Name" },
      { key: "last_name", label: "Last Name" },
      { key: "dob", label: "Date of Birth" },
      { key: "gender", label: "Gender" },
      { key: "blood_group", label: "Blood Group" },
      { key: "primary_mobile", label: "Mobile Number" },
    ];

    const missingFields = mandatoryFields.filter(({ key }) => 
      (key === "gender" || key === "blood_group") ? !formData[key]?.value : !formData[key]
    );

    if (missingFields.length > 0) {
      toast.error(
        <div>
          <p style={{ paddingLeft: '5px', marginTop: '5px' }}>Please fill in all mandatory fields:
          
            {missingFields.map(({ label }, index) => (
              <span key={index}>
                {label}{index < missingFields.length - 1 ? ', ' : ''}
              </span>
            ))}
          </p>
        </div>,
        { duration: 5000 }
      );
      return;
    }
    setCurrentStep(2);
  };

  // Modify the form content based on steps
  const renderFormContent = () => {
    if (currentStep === 1) {
      return (
        <>
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-1/5">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="member_title"
              >
                Title <span className="text-red-500">*</span>
              </label>
              <Select
                name="member_title"
                options={memberTitleOptions}
                styles={style}
                placeholder={placeHolderText("Title")}
                onChange={(selected) =>
                  dropDownChange(selected, { name: "member_title" })
                }
                value={memberTitleOptions.find(
                  (option) => option.value === formData.member_title
                )}
                className="w-full"
                menuPosition="fixed"
              />
            </div>
            <div className="w-full md:w-2/5">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="recipient_name"
              >
                First Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="recipient_name"
                id="recipient_name"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
                required
              />
            </div>
            <div className="w-full md:w-2/5">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="last_name"
              >
                Last Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
                required
              />
            </div>
          </div>
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-1/3">
              <label className="block mb-1 text-sm font-medium" htmlFor="dob">
                Date of Birth <span className="text-red-500">*</span>
              </label>
              <DatePicker
                name="dob"
                onChange={handleDateChange}
                className="w-full h-9"
                format="YYYY-MM-DD"
                required
                disabledDate={(current) =>
                  current && current > dayjs().endOf("day")
                }
              />
            </div>
            <div className="w-full md:w-1/3">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="gender"
              >
                Gender <span className="text-red-500">*</span>
              </label>
              <Select
                name="gender"
                options={genderOptions}
                styles={style}
                placeholder={placeHolderText("Select")}
                onChange={(selected) => {
                  console.log(selected);
                  setFormData({ ...formData, gender: selected });
                }}
                value={formData.gender}
                className="w-full"
                menuPosition="fixed"
              />
            </div>
            <div className="w-full md:w-1/3">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="blood_group"
              >
                Blood Group <span className="text-red-500">*</span>
              </label>
              <Select
                name="blood_group"
                options={bloodgroupOptions}
                styles={style}
                placeholder={placeHolderText("Select")}
                onChange={(selected) =>
                  dropDownChange(selected, { name: "blood_group" })
                }
                value={formData.blood_group}
                className="w-full"
                menuPosition="fixed"
                isSearchable={true}
              />
            </div>
          </div>
          <GeographicalComponent
            setFormData={setFormData}
            formData={formData}
            handleChange={handleChange}
            dropDownChange={dropDownChange}
            style={style}
            placeHolderText={placeHolderText}
          />
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-1/3">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="primary_mobile"
              >
                Primary Mobile <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="primary_mobile"
                id="primary_mobile"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
                required
              />
            </div>
            <div className="w-full md:w-1/3">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="secondary_mobile"
              >
                Secondary Mobile
              </label>
              <input
                type="text"
                name="secondary_mobile"
                id="secondary_mobile"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
              />
            </div>
            <div className="w-full md:w-1/3">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="whatsapp_number"
              >
                WhatsApp Number
              </label>
              <input
                type="text"
                name="whatsapp_number"
                id="whatsapp_number"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
              />
            </div>
          </div>
          <div className="md:flex md:space-x-4">
            <div className="w-full md:w-full">
              <label
                className="block mb-1 text-sm font-medium"
                htmlFor="primary_mobile"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                className="p-2 w-full rounded border border-gray-300 focus:outline-none"
                required
              />
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="national_id_type"
            >
              ID Type <span className="text-red-500">*</span>
            </label>
            <Select
              name="national_id_type"
              options={nidTypes?.filter(
                (type) => type.country.label === formData.country.label
              )}
              styles={style}
              placeholder={placeHolderText("Select ID Type")}
              onChange={(selected) =>
                setFormData({ ...formData, national_id_type: selected })
              }
              value={formData.national_id_type}
              className="w-full"
              menuPosition="fixed"
              isSearchable={true}
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="national_id_number"
            >
              National ID Number <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="national_id_number"
              id="national_id_number"
              onChange={handleChange}
              value={formData.national_id_number}
              className="p-2 w-full rounded border border-gray-300 focus:outline-none"
              disabled={!formData.national_id_type}
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-full">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="consent_upload"
            >
              Consent Upload <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name="consent_upload"
              id="consent_upload"
              onChange={(event) => setConsentFile(event.target.files[0])}
              className="p-2 w-full rounded border border-gray-300 focus:outline-none"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            />
            {consentFile && (
              <p className="mt-1 text-sm text-gray-500">
                Selected file: {consentFile.name}
              </p>
            )}
          </div>
        </div>
        <div className="mb-4 w-full">
          <label className="block mb-1 text-sm font-medium" htmlFor="comments">
            Comments
          </label>
          <textarea
            name="comments"
            id="comments"
            onChange={handleChange}
            value={formData.comments}
            className="p-2 w-full rounded border border-gray-300 focus:outline-none"
            rows="4"
          />
        </div>
      
        {/* {JSON.stringify(nidTypes)} */}
      </>
    );
  };

  const handleDownload = async () => {
    try {
      const fileUrl = `${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}${consentTemplate.fileUrl}`;
      const response = await fetch(fileUrl);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      // Create a temporary download link
      const link = document.createElement('a');
      link.href = url;
      link.download = consentTemplate.fileUrl.split('/').pop() || 'download';
  
      // Trigger the download
      document.body.appendChild(link);
      link.click();
  
      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Error downloading file. Please try again.');
    }
  };

  return (
    <div className="w-full p-3 bg-white rounded-lg shadow-lg max-h-[90vh] flex flex-col">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-2xl font-semibold">Add Recipient</h2>
          <p className="text-sm text-gray-500">Step {currentStep} of 2</p>
        </div>
        {renderButtons()}
      </div>
      <form id="recipientForm" className="overflow-y-auto pr-2 space-y-4">
        {renderFormContent()}
      </form>
      {/* {JSON.stringify(formData?.country)} */}
      {consentTemplate ? (
        <div className="flex flex-col items-start p-4 mt-4 bg-yellow-100 rounded-lg">
          <button
            onClick={handleDownload}
            className="flex gap-2 items-center px-4 py-2 text-white bg-blue-600 rounded transition duration-300 hover:bg-blue-700"
          >
            <DownloadOutlined /> Download Consent File
          </button>
          <p className="mt-2 text-xs font-semibold text-red-600">
            Download the Mandatory Consent file and once signed it should be uploaded on the next screen
          </p>
        </div>
      ) : null}

          {/* {JSON.stringify(consentTemplate)} */}
    </div>
  );
};

export default AddModal;
