import React from "react";
import { format } from "date-fns";
import {
  HiOutlineIdentification,
  HiOutlineGlobe,
  HiOutlineHashtag,
  HiOutlineAnnotation,
  HiOutlineClock,
  HiOutlineRefresh,
  HiOutlineCode,
} from "react-icons/hi";

const ViewModal = ({ currentRecord, open, onClose }) => {
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    try {
      return format(new Date(dateString), "PPP");
    } catch (error) {
      console.error("Date formatting error:", error);
      return "Invalid Date";
    }
  };

  return (
    <div className="p-6">
      <div className="w-full">
        {/* <h2 className="flex items-center gap-2 mb-8 text-2xl font-bold text-gray-800">
          <HiOutlineIdentification className="text-blue-600 w-7 h-7" />
          National ID Type Details
        </h2> */}

        <div className="grid grid-cols-2 gap-8">
          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineIdentification className="w-5 h-5 text-blue-500" />
              National ID
            </p>
            <p className="text-base font-semibold">{currentRecord.label}</p>
          </div>

          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineCode className="w-5 h-5 text-blue-500" />
              NID Code
            </p>
            <p className="text-base font-semibold">{currentRecord.nid_code}</p>
          </div>

          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineGlobe className="w-5 h-5 text-blue-500" />
              Country
            </p>
            <p className="text-base font-semibold">
              {currentRecord.country_name}
            </p>
          </div>

          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineHashtag className="w-5 h-5 text-blue-500" />
              Number of Digits
            </p>
            <p className="text-base font-semibold">
              {currentRecord.no_of_digits}
            </p>
          </div>

          <div className="col-span-2 p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineAnnotation className="w-5 h-5 text-blue-500" />
              Comments
            </p>
            <p className="text-base font-semibold">{currentRecord.comments}</p>
          </div>

          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineClock className="w-5 h-5 text-blue-500" />
              Created At
            </p>
            <p className="text-base font-semibold">
              {formatDate(currentRecord.createdAt)}
            </p>
          </div>

          <div className="p-4 transition-all rounded-lg bg-gray-50 hover:shadow-md">
            <p className="flex items-center gap-2 mb-2 text-sm font-medium text-gray-600">
              <HiOutlineRefresh className="w-5 h-5 text-blue-500" />
              Last Updated
            </p>
            <p className="text-base font-semibold">
              {formatDate(currentRecord.updatedAt)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModal;
