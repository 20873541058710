import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { countriesData } from "../Data/countries";
import { states_data } from "../Data/states";
import axios from "axios";

const GeographicalComponent = ({ setFormData, formData, style }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);

  const placeHolderText = (text) => (
    <span className="text-sm text-light">{text}</span>
  );
  // Initialize countries on component mount
  useEffect(() => {
    const formattedCountries = countriesData.map((c) => ({
      value: c.id,
      label: c.name,
    }));
    setCountries(formattedCountries);
  }, []);

  const fetchCities = useCallback(async (stateId) => {
    setIsLoadingCities(true);
    try {
      const userToken = localStorage.getItem("token");
      console.log("Fetching cities for stateId:", stateId);
      console.log(
        "API URL:",
        `${process.env.REACT_APP_API_URL}/city/${stateId}`
      );

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/city/${stateId}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Cities API Response:", response.data);

      if (response.data && response.data.cities) {
        const formattedCities = response.data.cities.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        console.log("Formatted cities:", formattedCities);
        return formattedCities;
      } else {
        console.error("Invalid cities data format:", response.data);
        return [];
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      console.error("Error details:", error.response?.data);
      return [];
    } finally {
      setIsLoadingCities(false);
    }
  }, []);

  // Update states when country changes
  useEffect(() => {
    if (formData.country) {
      const filterStates = states_data
        .filter((s) => s.country_id === formData.country.value)
        .map((s) => ({ value: s.id, label: s.name }));
      setStates(filterStates);
    } else {
      setStates([]);
    }
  }, [formData.country]);

  // Update cities when state changes
  useEffect(() => {
    const getCities = async () => {
      if (formData.state && formData.state.value) {
        console.log("State changed, fetching cities for:", formData.state);
        const citiesData = await fetchCities(formData.state.value);
        console.log("Received cities data:", citiesData);
        setCities(citiesData);
      } else {
        setCities([]);
      }
    };
    getCities();
  }, [formData.state, fetchCities]);

  const handleSelectChange = (selected, field) => {
    console.log(`${field} selected:`, selected);

    // If clearing the selection
    if (!selected) {
      if (field === "country") {
        setFormData((prev) => ({
          ...prev,
          country: null,
          state: null,
          city: null,
        }));
      } else if (field === "state") {
        setFormData((prev) => ({
          ...prev,
          state: null,
          city: null,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          city: null,
        }));
      }
      return;
    }

    // If selecting a new value
    if (field === "country") {
      setFormData((prev) => ({
        ...prev,
        country: selected,
        state: null,
        city: null,
      }));
    } else if (field === "state") {
      setFormData((prev) => ({
        ...prev,
        state: selected,
        city: null,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        city: selected,
      }));
    }
  };

  return (
    <div className="md:flex md:space-x-4">
      <div className="w-full md:w-1/3">
        <label className="block mb-1 text-sm font-medium" htmlFor="country">
          Country
        </label>
        <Select
          name="country"
          options={countries}
          styles={style}
          placeholder={placeHolderText("Select Country")}
          onChange={(selected) => handleSelectChange(selected, "country")}
          value={formData.country}
          className="w-full"
          menuPosition="fixed"
          isClearable
        />
      </div>
      <div className="w-full md:w-1/3">
        <label className="block mb-1 text-sm font-medium" htmlFor="state">
          State
        </label>
        <Select
          name="state"
          options={states}
          styles={style}
          placeholder={placeHolderText("Select State")}
          onChange={(selected) => handleSelectChange(selected, "state")}
          value={formData.state}
          className="w-full"
          menuPosition="fixed"
          isDisabled={!formData.country}
          isClearable
        />
      </div>
      <div className="w-full md:w-1/3">
        <label className="block mb-1 text-sm font-medium" htmlFor="city">
          City
        </label>
        <Select
          name="city"
          options={cities}
          styles={style}
          placeholder={placeHolderText("Select City")}
          onChange={(selected) => handleSelectChange(selected, "city")}
          value={formData.city}
          className="w-full"
          menuPosition="fixed"
          isDisabled={!formData.state}
          isClearable
          isLoading={isLoadingCities}
        />
      </div>
    </div>
  );
};

export default GeographicalComponent;
