import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import dayjs from "dayjs";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";

import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";

const ManageConsentsChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleView = (row) => {
    setCurrentRecord(row);
    toggleViewModal();
  };

  const handleEdit = (row) => {
    setCurrentRecord(row);
    toggleUpdateModal();
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this consent?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const response = await axios.delete(
                `${process.env.REACT_APP_API_URL}/consent/delete/${id}`,
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );
              if (response.data) {
                toast.success("Consent deleted successfully");
                getDataFromServer();
              }
            } catch (error) {
              console.error("Error deleting consent:", error);
              toast.error(error?.response?.data?.message || "Error deleting consent");
              errorLogApi(error, "ManageConsentsChild", "/consent/delete");
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const getDataFromServer = () => {
    let config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/consent`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data.consents;
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            id: data._id,
            ...data,
          };
        });
        setData(updatedData);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || "Error fetching data");
        errorLogApi(error, "ManageConsentsChild", "/consent/list");
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const columns = [
    {
        field: "actions",
        headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
        renderCell: (params) => (
          <div className="flex flex-row">
            <Tooltip title="View details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEdit(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row._id);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        ),
      },
    {
      field: "country",
      headerName: "Country",
      width: 150,
      renderCell: (params) => params.row.country.label,
    },
    {
      field: "version",
      headerName: "Version",
      sortable: true,
      hideable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
    },
    {
      field: "language",
      headerName: "Language",
      hideable: false,
      disableColumnMenu: true,
      width: 110,
      renderCell: (params) => params.value.toUpperCase(),
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <span
          className={`px-2 py-1 rounded-full text-xs ${
            params.value === "active"
              ? "bg-green-100 text-green-800"
              : params.value === "draft"
              ? "bg-yellow-100 text-yellow-800"
              : "bg-gray-100 text-gray-800"
          }`}
        >
          {params.value.charAt(0).toUpperCase() + params.value.slice(1)}
        </span>
      ),
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      width: 150,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      width: 150,
      renderCell: (params) =>
        params.value ? dayjs(params.value).format("YYYY-MM-DD") : "N/A",
    },
    {
      field: "fileUrl",
      headerName: "Document",
      width: 120,
      renderCell: (params) => (
        <a
          href={`${process.env.REACT_APP_REPORTS_DOWNLOAD_URL}${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 hover:text-blue-800"
        >
          View Document
        </a>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 180,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD HH:mm"),
    },
  
  ];

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="w-full">
      <div className="flex flex-col space-y-4">
        <div className="w-full">
          <div className="flex justify-between items-center">
            <h4 className="text-xl font-semibold">Manage Consents</h4>
          </div>
        </div>

        <div className="w-full">
          <div className="p-6">
            <div className="flex justify-end mb-4 w-full">
              <button
                type="button"
                className="flex items-center px-4 py-2 text-white bg-blue-600 rounded-md transition-colors duration-200 hover:bg-blue-700"
                onClick={toggleAddModal}
              >
                <i className="mr-2 mdi mdi-plus"></i>
                Add New Consent
              </button>
            </div>
            <div className="mb-4 w-full">
              <TextField
                fullWidth
                size="small"
                placeholder={placeHolderText}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className="w-full"
              />
            </div>

            <div className="h-[450px] w-full">
              <DataGrid
                rows={filteredData}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
                className="bg-white"
                displayTitle="Consents"
          checkboxSelection
          style={{ height: "40vh" }}
              />
            </div>
            <ErrorBoundaryRoutes route="/consents" page="ModalView">
              {showViewModal && (
                <Modal onClose={toggleViewModal}>
                  <ViewModal
                    showModal={showViewModal}
                    hideModal={toggleViewModal}
                    currentRecord={currentRecord}
                  />
                </Modal>
              )}
            </ErrorBoundaryRoutes>
            <ErrorBoundaryRoutes route="/consents" page="ModalView">
              {showAddModal && (
                <Modal onClose={toggleAddModal}>
                  <AddModal
                    showModal={showAddModal}
                    hideModal={toggleAddModal}
                    getDataFromServer={getDataFromServer}
                  />
                </Modal>
              )}
            </ErrorBoundaryRoutes>

            <ErrorBoundaryRoutes route="/consents" page="ModalView">
              {showUpdateModal && (
                <Modal onClose={toggleUpdateModal}>
                  <UpdateModal
                    showModal={showUpdateModal}
                    hideModal={toggleUpdateModal}
                    currentRecord={currentRecord}
                    getDataFromServer={getDataFromServer}
                  />
                </Modal>
              )}
            </ErrorBoundaryRoutes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageConsentsChild;